<template>
	<div class="full-height  ">
		<h6>{{ program.name }}</h6>

		<div
			class="  "
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<h6 class="under-line-identify">신청 구분</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="width-50-120">
						<col width="120px">
						<col width="width-50-120">
						<tbody>
						<tr>
							<th>소속 영업단 <span class="color-red">*</span></th>
							<td colspan="3">
								<div>

									<span
										v-if="item.upper_name || upper_name"
										class="box pa-5-10 vertical-middle mr-10 bg-eee"
									>{{ item.upper_name ? item.upper_name : upper_name }}</span>

									<button
										class="bg-identify pa-5-10 vertical-middle"
										@click="getAgencyList"
									>영업단 조회</button>

								</div>
							</td>
						</tr>
						<tr>
							<th>아이디 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.id"
									class="box pa-5-10 mr-10"
									placeholder="아이디를 입력하세요"
									:readonly="readonlyId"
								/>

								<button
									class="pa-5-10 bg-gray "
									:class="{ 'bg-green': is_id_check}"
									@click="doIdCheck"
								>중복 체크</button>
							</td>
							<th>가입일 <span class="color-red">*</span></th>
							<td class="position-relative">
								<input v-model="item.registerDate" placeholder="가입일" class="input-box" readonly @click="is_data_pick = !is_data_pick"/>
								<v-date-picker
									v-if="is_data_pick"
									v-model="item.registerDate"
									no-title
									scrollable
									class="position-absolute"
									style="top: 45px; right: 30px"
									@change="is_data_pick = false"
								></v-date-picker>
							</td>
						</tr>
						<tr>
							<th>비밀번호 <span class="color-red">*</span></th>
							<td class="">
								<input
									v-model="item.password"
									type="password"
									class="input-box full-width"
									placeholder="비밀번호를 입력하세요"
									max="50"
								/>
							</td>

							<th>비밀번호 확인 <span class="color-red">*</span></th>
							<td class="">
								<input
									v-model="item.passwordCk"
									type="password"
									class="input-box full-width"
									placeholder="비밀번호를 한번 더 입력하세요"
									max="50"
								/>
							</td>
						</tr>

						<tr>
							<th>수기결제 MID</th>
							<td class="">
								<input
									v-model="item.manualPaymentId"
									class="input-box full-width"
									placeholder="수기결제 MID"
									max="50"
								/>
							</td>

							<th>비밀번호 입력</th>
							<td class="">
								<input
									v-model="item.manualPaymentPassword"
									type="password"
									class="input-box full-width"
									placeholder="수기결제 계정 비밀번호"
									max="50"
								/>
							</td>
						</tr>

						<tr>
							<th>빌키결제 MID</th>
							<td class="">
								<input
									v-model="item.billKeyPaymentId"
									class="input-box full-width"
									placeholder="빌키결제 MID"
									max="50"
								/>
							</td>

							<th>비밀번호 입력</th>
							<td class="">
								<input
									v-model="item.billKeyPaymentPassword"
									type="password"
									class="input-box full-width"
									placeholder="빌키결제 계정 비밀번호"
									max="50"
								/>
							</td>
						</tr>
						</tbody>
					</table>

				</div>

				<div class="flex-1 bg-white pa-10">
					<h6 class="mt-10 under-line-identify">대표자 정보</h6>

					<table class="table th-left td-left">

						<col width="120px" />
						<col width="width-50-120" />
						<col width="120px" />
						<col width="width-50-120" />

						<tbody>
						<tr>
							<th>대표자명 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.name"
									class="box pa-5-10 mr-10"
									placeholder="대표자명을 입력하세요"
								/>
							</td>
							<th>휴대폰 <span class="color-red">*</span></th>
							<td>
								<input
									v-model="item.hp"
									class="box pa-5-10 mr-10"
									placeholder="대표자 휴대폰번호를 입력하세요"
								/>
							</td>
						</tr>
						<tr>
							<th>자택주소 <span class="color-red">*</span></th>
							<td colspan="3">

								<input
									v-model="item.homeZipCode"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('home')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('home')"
								>주소 검색</button>

								<input
									v-model="item.homeAddress"

									class="input-box  mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('home')"
								>

								<input
									v-model="item.homeAddressDetail"

									class="input-box  mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="mt-10 justify-space-between">
				<div class="flex-1 mr-10 ">
					<div class="pa-10 bg-white">
						<div
							class="under-line-identify justify-space-between"
						>
							<h6>서비스 이용 조건</h6>
							<span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
						</div>

						<table class="table th-left td-left">
							<col width="120px" />
							<col width="auto" />
							<tbody>
								<tr>
									<th>서비스 수수료 <span class="color-red">*</span></th>
									<td>
										<div class="justify-space-between">
											<div class="flex-1">
												영업단 수익
												<input v-model="fee2" class="input-box-inline bg-identify text-right width-50px color-eee" readonly /> %
											</div>
											<div class="flex-2">
												수수료 할인
												<input
													v-model="item.fee" class="input-box-inline width-50px text-right" @change="checkFee()"
													:rules="[rules.demical(item, 'fee', { min: 2, max: 2})]"
												/> %
												(최대 {{ maxAvailableFee }} %)
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<th>한도 설정  <span class="color-red">*</span></th>
									<td>
										<input
											type="number"
											v-model="item.oneLimit"
											placeholder="1회 한도"
											class="input-box-inline text-right "
											:rules="[rules.max(item, 'limit_first', 5)]"
											/> 만원
										<input
											type="number"
											v-model="item.monthlyLimit"
											placeholder="월 한도"
											class="input-box-inline text-right"
											:rules="[rules.max(item, 'limit_month', 6)]"
										/> 만원
										<input
											type="number"
											v-model="item.yearlyLimit"
											placeholder="연 한도"
											class="input-box-inline text-right"
											:rules="[rules.max(item, 'limit_year', 7)]"
										/> 만원
									</td>
								</tr>
								<tr>
									<th>정산주기 <span class="color-red">*</span></th>
									<td>
										<label

											v-for="code in codes.P03"
											:key="code.TOTAL_CODE"

											class="cont-radio mr-10 pa-5-10"
											:class="item.settlementCycle == code.TOTAL_CODE ? 'bg-identify' : 'bg-bbb'"
										>
											<input
												v-show="false"
												v-model="item.settlementCycle"
												type="radio"
												:value="code.TOTAL_CODE"
												class="box"
											/>
											{{ code.code_name }}
										</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="pa-10 bg-white mt-10">
						<h6>매출알림 서비스</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>여신사 아이디</th>
								<td>
									<input
										v-model="item.cardId"
										class="input-box full-width"
										:rules="[rules.max(item, 'cardId', 25)]"
										placeholder="여신사 아이디를 입력하세요"
									/>
								</td>
								<th>여신사 비밀번호</th>
								<td>
									<input
										v-model="item.cardPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'cardPassword', 25)]"
										placeholder="여신사 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>배달의 민족 아이디</th>
								<td>
									<input
										v-model="item.baeminId"
										class="input-box full-width"
										:rules="[rules.max(item, 'baeminId', 25)]"
										placeholder="배달의 민족 아이디를 입력하세요"
									/>
								</td>
								<th>배달의 민족 비밀번호</th>
								<td>
									<input
										v-model="item.baeminPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'baeminPassword', 25)]"
										placeholder="배달의 민족 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>요기요 아이디</th>
								<td>
									<input
										v-model="item.yogiyoId"
										class="input-box full-width"
										:rules="[rules.max(item, 'yogiyoId', 25)]"
										placeholder="요기요 아이디를 입력하세요"
									/>
								</td>
								<th>요기요  비밀번호</th>
								<td>
									<input
										v-model="item.yogiyoPassword"
										type="password"
										class="input-box full-width"
										:rules="[rules.max(item, 'yogiyoPassword', 25)]"
										placeholder="요기요 비밀번호를 입력하세요"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1 bg-white">

					<div class="pa-10">

						<h6 class="under-line-identify">사업자 정보</h6>
						<table class="table th-left td-left">
							<col width="120px">
							<col width="width-50-120">
							<col width="120px">
							<col width="width-50-120">
							<tbody>
							<tr>
								<th>사업자 구분 <span class="color-red">*</span></th>
								<td colspan="3">
									<label
										class="cont-radio"
										v-for="code in codes.P02"
										:key="code.TOTAL_CODE"
									>
										<input
											v-model="item.businessType"
											type="radio"
											:value="code.TOTAL_CODE"
											class="box"
										/>
										{{ code.code_name }}
									</label>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '1'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 10)]"
										class="input-box-inline"
										placeholder="사업자 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '0'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 10)]"
										class="input-box"
										placeholder="사업자 등록 번호"
									/>
								</td>
								<th>법인등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.corporationNumber"
										:ruels="[rules.max(item, 'corporationNumber', 13)]"
										class="input-box"
										placeholder="법인 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '2'"
							>
								<th>주민등록 번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.frontSSN"
										type="number"
										:ruels="[rules.max(item, 'frontSSN', 6)]"
										class="input-box-inline mr-10"
										placeholder="주민등록 앞번호"
									/>
									<input
										v-model="item.backSSN"
										type="password"
										maxlength="7"
										class="input-box-inline"
										placeholder="주민등록 뒷번호"
									/>
								</td>
							</tr>
							<tr>
								<th>상호명 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="text"
										v-model="item.shopName"
										maxlength="50"
										class="input-box"
										placeholder="상호명을 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>유선 전화</th>
								<td>
									<input
										type="number"
										v-model="item.linePhone"
										:ruels="[rules.max(item, 'linePhone', 12)]"
										class="input-box full-width"
										placeholder="유선전화번호를 입력하세요"
									/>
								</td>
								<th>팩스 번호</th>
								<td>
									<input
										type="number"
										v-model="item.fax"
										:ruels="[rules.max(item, 'fax', 12)]"
										class="input-box full-width"
										placeholder="팩스번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>사업장 주소 <span class="color-red">*</span></th>
								<td colspan="3">

									<input
										v-model="item.zipCode"

										class="input-box-inline mr-10"
										type="text" placeholder="우편번호"
										readonly

										@click="daumPost('company')"
									>

									<button
										class="box pa-5-10 bg-identify"
										@click="daumPost('company')"
									>주소 검색</button>

									<input
										v-model="item.address"

										class="input-box full-width mt-10" type="text" placeholder="주소"
										readonly

										@click="daumPost('company')"
									>
									<input
										v-model="item.addressDetail"

										class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
										maxlength="50"
									>
								</td>
							</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>

			<div class="mt-10 justify-space-between">
				<div class="flex-1 mr-10 ">
					<div class="pa-10 bg-white">
						<h6>정산 계좌 정보</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>은행  <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.calculateBankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.calculateAccount"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'calculateAccount', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.calculateAccountHolder"
										class="input-box-inline mr-10"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일 <span class="color-red">*</span></th>
								<td>
									<input
										type="text"
										v-model="item.email"
										maxlength="50"
										class="input-box"
										placeholder="세금계산서 발행 이메일을 입력하세요"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div
						v-if="false"
						class="mt-10 bg-white pa-10"
					>
						<h6>배대사 계좌 정보</h6>
						<table class="table th-left td-left top-line-identify">
							<col width="120px" />
							<col width="width-50-120" />
							<col width="120px" />
							<col width="width-50-120" />
							<tbody>
							<tr>
								<th>은행  <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.bankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'account', 25)]"
										placeholder="배대사 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.accountHolder"
										class="input-box-inline mr-10"
										placeholder="배대사 계좌 예금주"
										maxlength="15"
									/>

									<button
										class="bg-identify pa-5-10"
										@click="isHolder"
									>예금주 조회</button>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1 bg-white">

					<div class=" pa-10 ">
						<h6>첨부파일</h6>
						<table class="table th-left td-left full-width top-line-identify">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>계약서류</th>
								<td>
									<div
										v-for="(file, index) in codes.merchant_files"
										:key="'file_' + index"

										class=" ptb-10"
									>
										<label class="box pa-5-10 mr-10 vertical-middle">
											<input
												v-show="false"
												type="file"
												accept="image/*"
												@change="setFile($event, file.code)"
											/>
											<v-icon
												small
											>mdi mdi-image-multiple</v-icon> {{  file.name }}
										</label>

										<button
											v-if="item[file.code]"
											:key="'file_delete_' + file.code"
											@click="isDeleteFile(file.code)"
											class="btn-default pa-5-10 vertical-middle"
										>{{ item[file.code].name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</div>

									<div class="mt-10">
										<label class="box pa-5-10 vertical-middle">
											<input
												v-show="false"
												type="file"
												multiple
												accept="image/*"
												@change="setFile($event, 'attachment')"
											/>
											<v-icon
												small
											>mdi mdi-image-multiple</v-icon> 첨부파일
										</label>
									</div>

									<div class="mt-10">
										<button
											v-for="(file, index) in item.attachments"
											:key="'file_delete_' + index"
											@click="isDeleteFile('attachment', index)"
											class="btn-default pa-5-10 mr-10 mb-10"
										>{{ file.name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mtb-30 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="bg-ddd pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="false"

			title="소속 영업단 조회"
			height="850px"

			@close="clear"
		>
			<AgencySearchList
				slot="modal-content"

				:item_type="item.type"
				:user="user"

				@click="setUpper"
				@cancel="clear"
				@next="nextUpper"
			></AgencySearchList>
		</Modal>
		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			width="380px"

			@click="setNewId"
			@close="clear"
			@cancel="clear"
		>
			<div slot="modal-content">
				이미 사용중인 아이디입니다. <br/>
				확인시 서브 계정 아이디로 생성됩니다.
			</div>
		</Modal>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";
export default {
	name: 'MerchantItem'
	,
	components: {AgencySearchList, DaumPost, Modal},
	props: ['Axios', 'user', 'codes', 'rules', 'date']
	,data: function(){
		return {
			program: {
				name: '가맹점 등록'
				, top: true
				, title: true
				, bottom: false
			}
			,fee_base: 4
			,is_data_pick: false
			,item: {
				type: 99
				, branchIdx: ''
				, distributorIdx: ''
				, agencyIdx: ''
				, resellerIdx: ''
				, subresellerIdx: ''
				, upperName: ''
				, registerDate: this.date.getToday('-')
				, fee2: 0
				, fee: 0
				, settlementCycle: 1
				, attachments: []
				, businessType: 0
				, bankIdx: ''
				, calculateBankIdx: ''
				, frontSSN: ''
				, businessNumber: ''
				, corporationNumber: ''
                , cardId: ''
                , cardPassword: ''
                , baeminId: ''
                , baeminPassword: ''
                , yogiyoId: ''
                , yogiyoPassword: ''
			}
			,readonlyId: false
			,is_id_check: false
			,is_modal: false
			,modal_option: {
			}
			,is_modal2: false
			,modal_option2: {
				title: '아이디 조회'
				,top: true
				,content: '이미 사용중인 아이디 입니다. 서브 계정아이디로 생성됩니다.'
				,bottom: true
			}
			,daum_type: ''
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,agency_list: []
			,upperIdx: ''
			,fee2: 0
			,maxAvailableFee: 0
		}
	}
	,computed: {

		upper_name: function(){
			let upper_name = ''
			return upper_name
		}
	}
	, methods: {
		getAgencyList: async function(){
			this.is_modal = true
		}
		,save: async function(){
            for (const [key, value] of Object.entries(this.item)) {
                if (value === 'null') {
                    this.item[key] = ''
                }
                if(typeof value === 'string') {
                    if(value.indexOf('undef') !== -1 ) {
                        this.item[key] = ''
                    }
                }
            }
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'post'
					, url: 'shop/'
					, data: this.item
					, multipart: true
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.$emit('push', { name: 'MerchantList'})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,clear: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.upperIdx = ''
			this.agency_list = []
			this.readonlyId = false
		}
		,setNewId: function(){
			this.readonlyId = true
			this.is_modal2 = false
			this.is_id_check = true
		}
		,toList: function(){
			this.$router.back()
		}

		,daumPost: function (type) {
			this.daum_type = type
			this.is_post = true
		}
		, addPost: function (call) {

			if(this.daum_type == 'company'){
				this.$set(this.item, 'zipCode', call.zonecode)
				this.$set(this.item, 'address', call.address)
			}else{
				this.$set(this.item, 'homeZipCode', call.zonecode)
				this.$set(this.item, 'homeAddress', call.address)
			}

			this.is_post = false
		}
		,isDeleteFile: function(type, index){
			if(type == 'attachment'){
				this.$delete(this.item.attachments, index)
			}else{
				this.$delete(this.item, type)
			}

		}
		,doFileDelete: async function(){

		}
		,doIdCheck: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/checkId'
					,data: {
						checkId: this.item.id
					}
				})
				if(result.success){
					if(result.data){
						if(result.data.duplicate){
							this.item.id = result.data.newID
							this.readonlyId = true
							this.is_modal2 = true
							this.is_id_check = true
						}else{
							this.$emit('setNotify', { type: 'success', message: '사용 가능한 아이디 입니다'})
							this.is_id_check = true
						}
					}else{
						this.$emit('setNotify', { type: 'error', message: '사용중인 아이디입니다'})
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isHolder: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/checkAccount'
					,data: {
						accountNumber: this.item.account
						, idx: this.item.bankIdx
						, name: this.item.accountHolder
					}
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}

		,isFee: function(service){
			if(this.fee_base < this.item[service]){
				this.$emit('setNotify', { type: 'error', message: '본사수익률보다 높게 설정할 수 없습니다.'})
				this.item[service] = 0
			}
		}
		, setUpper: function(item, item_list){

			this.item.branchIdx = item_list.branchList.idx
			this.item.upper_name = item_list.branchList.name
			this.item.distributorIdx = ''
			this.item.agencyIdx = ''
			this.item.resellerIdx = ''
			this.item.subResellerIdx = ''

			if(item.type >= item_list.distributorList.type) {
				this.item.distributorIdx = item_list.distributorList.idx
				this.item.upper_name += ' > ' + item_list.distributorList.name
			}

			if(item.type >= item_list.agencyList.type) {
				this.item.agencyIdx = item_list.agencyList.idx
				this.item.upper_name += ' > ' + item_list.agencyList.name
			}

			if(item.type >= item_list.resellerList.type) {
				this.item.resellerIdx = item_list.resellerList.idx
				this.item.upper_name += ' > ' + item_list.resellerList.name
			}

			if(item.type >= item_list.subResellerList.type) {
				this.item.subresellerIdx = item_list.subResellerList.idx
				this.item.upper_name += ' > ' + item_list.subResellerList.name
			}

			this.clear()
			this.upperIdx = item.idx
		}
		,getFee: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'shop/fee'
					,data: {
						upperAdminIdx: this.upperIdx
					}
				})
				if(result.success){
					this.fee2 = result.data.upperFee
					this.maxAvailableFee = result.data.maxAvailableFee
					// this.item.fee2 = Number(this.fee2 - this.item.fee).toFixed(2)
					// this.item.fee3 = result.data.mainFee
					//this.item.fee = result.data.mainFee
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,setFile: function(e, type) {
            console.log(type)
            console.log(e.target.files)
			if(type == 'attachment'){
				for (let i = 0; i < e.target.files.length; i++) {
					this.item.attachments.push(e.target.files[i])
				}
			}else{
				for (let i = 0; i < this.codes.merchant_files.length; i++) {
                    console.log(this.codes.merchant_files[i].code)
					if(type == this.codes.merchant_files[i].code) {
                        console.log(e.target.files[0])
						this.$set(this.item, this.codes.merchant_files[i].code, e.target.files[0])
					}
				}
			}
            console.log(this.item.attachments)
            console.log(this.item)
		}
		,resetUpper: function(){
			this.upperIdx = ''
			this.item.upper_name = ''
		}
		,nextUpper: function(type, sub){
			this.upperIdx = sub.idx
			this.getAgencyList(type)
		}
		,checkFee: function(){
			if(this.maxAvailableFee < this.item.fee){
				this.$emit('setNotify', { type: 'error', message: '수수료할인률은 영업단 수익보다 높을수 없습니다'})
				this.item.fee = 0
				// this.item.fee2 = this.fee2
			}else{
				// this.item.fee2 = Number(this.fee2 - this.item.fee).toFixed(2)
				// this.item.fee2 = this.fee2
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
	}
	,watch: {
		'item.id': {
			handler: function(){
				this.is_id_check = false
			}
		}
		,upperIdx: {
			handler: function(call){
				if(call) {
					this.getFee()
				}
			}
		}
		,'item.businessType': function(){
			this.item.businessNumber = ''
			this.item.corporationNumber = ''
			this.item.frontSSN = ''
			this.item.backSSN = ''
		}
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
</style>